.blog-wrapper {
    max-width: 95%;
    margin: 0 auto;
}
.blog-goBack {
    display: block;
    font-size: 1.3rem;;
    font-weight: 700;
    margin-bottom: 2rem;
}
.blog-goBack span { color: red;}
.blog-goBack span:hover { font-weight: 500; color: white; text-transform: uppercase;}

.blog-header {
    padding-bottom: 20px;
}

.blog-wrapper header {
    text-align: center;
}
.myPaddingBottom {
    padding-bottom: 10px;
}
.individualBlogH1 {
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    color: #caa532;
}
.blockquoteBlog {
    color: red; 
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
}
.blockquoteBlog::before, .blockquoteBlog::after{font-size: 5rem; font-family: 'Times', 'roboto';}
.blockquoteBlog::before {content: '\201c';}
.blockquoteBlog::after {content: '\201d';}
.tradingDate,
.sessionInstrument,
.sessionNetProfit {
    color:#caa532;
}
.tradingDate, 
.avatarTradingDate {text-transform: capitalize;}

.blog-wrapper img {
    width: 100%;
    height: 100%;
    padding: 0 1rem 0 0;
}
.img-wrapper img {
    width: 48%;
    height: 100%;
    padding: 0 1rem 0 0;
}
.myParagraph,
.explanation {
    color: #a9a9a9;
}
.explanation {font-size: .8rem;}
.sessionSummaryTimestamp,
.timeStampMackChart{ margin: .5rem 0 .5rem;}
.tradeImage-wrapper { margin: 0;}
.tradeImg {
    width: 100% !important;
    margin:0 0 2rem 0;
}
.headerMargin {
    margin-top: 1rem;
}
/*
.tradeImage-wrapper img {
    width: 50%;
    max-height: 400px;
    object-fit:contain;
    object-position: left;
} */
.blog-subCategory {
    display: flex;
    justify-content: center;
}
.blog-subCategory >div {
    margin: 1rem;
}
.blog-descriptions {
    color: #a9a9a9;
}
.tradeStats {
    margin: 10px 0 20px;
}
/*.reasonToTakeTrade {
    font-weight: 400;
}*/
.tradeText {
    margin-top: 0;
    font-size: 1.3rem;
    color: #a9a9a9;
}
.individualTA {
    font-size: 2rem;
    text-align: start;
    text-transform: uppercase;
}
.myWhite {
    color: white;
}
.myh1 {
    margin-top: 50px;
    text-align: start;
    color: #a9a9a9;
}
.myh2 {
    font-size: 3.5rem;
}
.summary,
.sessionReflection,
.todaysNews {
    margin: 5px 0 10px;
    color: white;
}
.center {
    text-align: center;
}
.myListItem {
    color: white;
    font-size: 1.1rem;
    list-style-type: none;
}
.arrow {
    font-size: 1rem;
    margin: 0;
    color: #a9a9a9;
}
.white {color: white;}
.tradingDate::before {content: 'Trading date: '; color:rgb(156, 155, 155); text-transform: uppercase;}
.sessionInstrument::before {content: 'Session & Instrument: '; color:rgb(156, 155, 155); text-transform: uppercase;}
.sessionNetProfit::before {content: 'Net profit: '; color:rgb(156, 155, 155); text-transform: uppercase;}

.profitPerMonth::before {content: 'Profit Per Month: '; color: white;}
.MAE::before {content: 'Average MAE (Maximum Adverse Excursion): '; color: white;}
.MFE::before {content: 'Average MFE (Maximum Favorable Excursion): '; color: white;}
.ETD::before {content: 'Average ETD (End Trade Drawdown): '; color: white;}
.avgTimeInMarket::before {content: 'Average Time in Market: '; color: white;}
.totalEfficiency::before {content: 'Total efficiency: '; color: white;}
.avgTradeDollar::before {content: 'Av. trade: '; color: white;}

.yesterdaysCloseDarkOrange::before {content: 'DarkOrange line: '; color: darkorange;}
.todayOpenDarkRed::before {content: 'DarkRed line: '; color: darkred;}
.mySessionArea::before {content: 'My trading session: '; color: gray;}
.mySessionArea::after {content: 'I am scalping the US session between 15:30-22:30 (CET +1 hour)).'; color: #a9a9a9;}

.arrowGold::before {content: 'Gold arrow (up & down): '; color: Gold;}
.arrowRed::before {content: 'Red arrow (down): '; color: red;}
.arrowLime::before {content: 'Lime arrow (up): '; color: lime;}
.arrowGreen::before {content: 'Green arrow:(up/down): '; color: green;}
.arrowCyan::before {content: 'Cyan arrow:(up/down): '; color: cyan;}

.myYellow { color:#caa532; text-transform: uppercase;}

.StatsHeader { color:#caa532; font-weight: 700; text-decoration: underline;}

.chart1SummaryTimeStamp, 
.chart2SummaryTimeStamp, 
.chart3SummaryTimeStamp {color: antiquewhite; font-style: italic;}
.instrumentColorType {color: antiquewhite; text-transform:uppercase}

.chip::before {content: 'Main trend: '; color: black; font-weight: 900;}
.summary::before {content: 'Summary: '; color: #caa532; font-weight: 700;}
.mackComparison::before {content: 'Most significant lessons form Mack: '; color: #caa532; font-weight: 700;}
.SignificantTimeStamps::before {content: 'Significant time stamps '; color: #caa532; font-weight: 700; text-transform: capitalize;}
.chart1InstrumentType::before,
.chart2InstrumentType::before,
.chart3InstrumentType::before {content: 'Instrument traded: '; color: #caa532; font-weight: 700; text-transform: capitalize;}
.sessionReflection::before {content: 'Session reflection: '; color: #caa532; font-weight: 700;}
.significantDiff::before {content: 'Significant difference: '; color: #caa532; font-weight: 700;}
.todaysNews::before {content: 'Todays news: '; color: #caa532; font-weight: 700;}

/*Session stats*/
.tradesDuringSession::before {content: 'Total # trades during session: '; color: #caa532; font-weight: 600;}
.tradesDuringSession::after {content: ' trades';}
.numOfAccounts::before {content: 'Total # accounts: '; color: #caa532; font-weight: 600;}
.numOfAccounts::after {content: ' accounts';}
.numOfTradedContracts::before {content: 'Total # contracts Traded: '; color: #caa532; font-weight: 600;}
.numOfTradedContracts::after {content: ' contracts';}

.netProfitDollar::before {content: 'Total Net profit/loss: '; color: #caa532; font-weight: 600;}
.totalCommission::before {content: 'Total commission: '; color: #caa532; font-weight: 600;}
.netProfitPerAccount::before {content: 'Total Net profit/loss per account: '; color: #caa532; font-weight: 600;}
/* .netProfitPerAccount::after {content: ' ($)';} */
.netProfitTicks::before {content: '# ticks (all accounts): '; color: #caa532; font-weight: 600;}
.netProfitTicks::after {content: ' ticks';}

.winPercentage::before {content: 'Win percentage: '; color: #caa532; font-weight: 600;}
.winPercentage::after {content: ' (goal is above 60%)';}
.numOfBE::before {content: '# of even trades: '; color: #caa532; font-weight: 600;}
.numOfWinningTrades::before {content: '# of winning trades: '; color: #caa532; font-weight: 600;}
.numOfWinningTrades::after {content: ' winners'; color: lime;}
.numOfLoosingTrades::before {content: '# of loosing trades: '; color: #caa532; font-weight: 600;}
.numOfLoosingTrades::after {content: ' losers'; color: red;}
.avgWinner::before {content: 'Avg. winning trade: '; color: #caa532; font-weight: 600;}
.avgWinner::after {content: ' '; color: lime;}
.avgLooser::before {content: 'Avg. loosing trade: '; color: #caa532; font-weight: 600;}
.avgLooser::after {content: ' '; color: red;}
.mySmallText {font-size: .8rem;}

.profitColor { color: lime;}
.lossColor { color: red;}

.NumOfTarget::before {content: 'Scalping targets: '; color: white;}
.totalScalpTicks::before {content: 'Scalping ticks: '; color: white;}
.breakEvenScalp::before {content: 'BE scalps: '; color: white; }
.totalRunnersTicks::before {content: 'Runner ticks: '; color: white;}
.breakEvenRunners::before {content: 'BE runners: '; color: white; }

.winners, .losers { color: white;}

.grossProfit { color: lime;}
.grossProfit::after {content: ' gross profit.'; color: white;}
.grossProfit::before {content: ' winner(s) $'; color: white;}

.grossLoss {color: red;}
.grossLoss::after {content: ' gross loss.'; color: white;}
.grossLoss::before {content: ' loser(s) $ '; color: white;}

/* trade stats section*/
.statsMargin {margin-left: 0rem;}

.tradeHeader {color: #caa532; text-transform: capitalize; text-decoration: underline; text-decoration-color: rgb(156, 155, 155);}

.tradedContracts,
.NumOfAccounts,
.timeOfEntry,
.typeOfTrade,
.tradeRR,
.usedRiskManagement,
.tradeRunner,
.tradeScalp {color:white; font-style: italic; font-family: 'Roboto';}

.totalTradeTicks {color:#caa532; padding: 10px 0;}

@media (max-width: 900px) {
    .blog-wrapper {
        max-width: 100%;
    }
    .blog-wrapper img {
        width: 100%;
        padding: 0 0 1rem 0;
    }
    .img-wrapper img {
        width: 100%;
    }
    .myPaddingBottom {
        padding-bottom: 0px;
    }
    .blockquoteBlog {font-size: 1rem;}
    .blockquoteBlog::before, .blockquoteBlog::after{font-size: 3rem;}
    .myParagraph,
    .explanation,
    .timeStampMackChart,
    .instrumentComparison,
    .sessionSummaryTimestamp,
    .netProfit,
    .mySmallText {
        font-size: .7rem;
    }
    .sessionSummaryTimestamp,
    .timeStampMackChart{ margin: .9rem 0 .9rem;}
    .blogList-wrapper {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 4rem;
    }
    .blogItem-wrapper:first-child {
        margin-bottom: 3rem
    }
    .blogItem-wrapper {
        max-height: 570px;
        margin-top: 3rem
    }
    .individualBlogH1 {
        font-size: 1.3rem;
    }
    .individualTA {
        font-size: 1.5rem;
    }
    .arrow {
        font-size: .9rem;
    }
    .myh1 {
        margin-top: 20px;
        text-align: start;
        font-size: 1.4rem;
    }
    .myh2 {
        font-size: 1.2rem;
    }
    .myh3 {
        font-size: 1.1rem;
    }
    .myh4 {
        font-size: 1rem !important;
    }
    .myh5 {
        font-size: 1.1rem;
    }
    .summary,
    .summaryStats,
    .myListItem,
    .tradeText,
    .sessionReflection,
    .todaysNews {
        font-size: 1rem;
    }
    .chip {
        display: none;
    }
    .tradeStats {
        margin-bottom: 20px;
    }
    .tradeImg {
        width: 100% !important;
        margin:0 0 .75rem 0;
    }
    .blog-goBack span {
        font-size: .75rem;
    }
}
