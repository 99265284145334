.searchBar-wrapper {
    background-color: #9b9b9b;
    width: fit-content;
    margin: 1.5rem auto 4rem auto;
    padding: .5rem;
    border-radius: 5px;
}
::placeholder { color: black;}
.searchBar-wrapper form {
    display: flex;
    align-items: center;
}

.searchBar-wrapper input {
    background-color: #9b9b9b;
    outline: none;
    border: none;
    font-style: italic;
    font-size: .9rem;
    color: black;
}
.searchBar-wrapper span {
    padding-right: .5rem;
    cursor: pointer;
}
.searchBar-wrapper button {
    outline: none;
    border: 1px solid black;
    padding: .3rem 1rem;
    border-radius: 5px;
    background-color: black;
    color: #caa532;
    font-size: 1rem;
    cursor: pointer;
}
.clearSpan{ color: black; text-transform: capitalize; font-weight: 600;}
