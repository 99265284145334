.blogItem-wrapper {
    /* display: flex; */
    flex-direction: column;
}
.blogItem-cover {
    width: 100%;
    height: 100%;
    max-height: 275px;
    object-fit:fill;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.blogItem-wrapper .myh3 {
    margin: 0.5rem 0 0 0;
    color: #caa532;
}
.blockquoteBlogItem {
  display: block;
  position: relative;
  
  margin-bottom: 30px;
  margin-top: 20px;
  text-align: center;
  color: red;
}
.blockquoteBlogItem::before, .blockquoteBlogItem::after{font-size: 2rem; font-family: 'Times', 'roboto';}
.blockquoteBlogItem::before {
  content: '\201c';
  top: 10px;
  left: -40px;
}
.blockquoteBlogItem::after {
  content: '\201d';
  left: -40px;
}

.textBody {
  height: 80px;
}
.startText {
  overflow-y: scroll;
  padding-bottom: 0!important;
}
.startText::-webkit-scrollbar {
  width:.8vh;
}
.startText::-webkit-scrollbar-thumb {
background-color: #a9a9a9;
}
/* .startText::-webkit-scrollbar-thumb:hover {
  background-color: #a9a9a9;
} */
.startText::-webkit-scrollbar-track {
background-color: black;
}
.blogItem-description {
    position: relative;
    overflow: hidden;
    padding-right: 0.6rem;
    margin: 0;
    font-size: 1rem;
    color: #a9a9a9;
  }
  .blogItem-description::after{content:'Read more -->'; color: #caa532; padding-left: 2rem; font-weight: 700;}
  .blogItem-description:hover { 
    font-weight: 500;
    color: whitesmoke;
  }
  .footer-wrapper {margin-bottom: 60px;}

  .blogItem-wrap footer {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
  }

  .blogItem-link {
    text-decoration: none;
    color: inherit;
  }
  .avatarTraderName {
    margin-bottom: 5px;
  }
  .blogItem-author {
    display: flex;
    align-items: flex-end;
  }
  .blogItem-author p {
    margin-bottom: 0;
  }
  
  .blogItem-author img {
    width: 10%;
    height: 10%;
    border-radius: 5%;
    object-fit: contain;
    margin-right: 0.5rem;
  }
  
  .blogItem-author p {
    font-size: 0.6rem;
    color: #a9a9a9;
    font-weight: 600;
  }
  .avatarTradingDate { color: white !important; font-size: .7rem !important;}
  .titleSection {
    display: flex;
    align-items: flex-end;
  }
  .myh6,
  .myhr { color:#caa532 }

  .readMore:hover {
    font-weight: 900;
  } 
  .arrow {
    font-size: 1.75rem;
  }
  
  @media (min-width: 1500px) {
    .textBody {
      height: 80px;
    }
}
@media (max-width: 768px) {
  .textBody {
    min-height: 70px;
  }
  
}
  
    