.home-header {
    text-align: justify;
    text-align: center;
    margin: 0 75px;
}
.capitalize {text-transform: capitalize;}
.headerH1 {
    color: #caa532;
    font-size: 4.5rem;
    text-align: center;
    text-transform: uppercase; 
}

.headerQuote {
    color: red; 
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    text-transform: capitalize;
}
.system_0::before{content: 'Fixed exit target: '; color: #caa532; text-transform: uppercase;}
.system_1::before{content: '2000-tick trend chart: '; color: #caa532; text-transform: capitalize;}
.system_2::before{content: '500-tick chart: '; color: #caa532; text-transform: capitalize;}
.system_3::before{content: 'Indicator: '; color: #caa532; text-transform: capitalize;}
.system_4::before{content: 'Drawing tools: '; color: #caa532; text-transform: capitalize;}
.system_5::before{content: 'Main trend chart analysis: '; color: #caa532; text-transform: capitalize;}
.system_6::before{content: 'Entry chart analysis: '; color: #caa532; text-transform: capitalize;}
.headerQuote::before, .headerQuote::after{font-size: 5rem; font-family: 'Times', 'roboto';}
.headerQuote::before {content: '\201c';}
.headerQuote::after {content: '\201d';}
.kaizen {width: 50%;}

.chartSearchTags::before { content: 'Chart tags: '; color: #caa532;}
.learningSearchTags::before { content: 'Learning tags: '; color: #caa532;}
.instrumentSearchTags::before { content: 'Instrument tags: '; color: #caa532;}

/* .chartSearchTags {padding-top: 20px;} */
.chartSearchTags,
.learningSearchTags,
.instrumentSearchTags {font-style: italic; font-weight: 400; text-transform: capitalize;}
.dashMarks {color: red; text-transform: capitalize;}
.dashMarks::before {content: '-- ';}
.dashMarks::after {content: ' --';}



@media (max-width: 600px) {
    .home-header{ margin: 0;}
    .myh2{ font-size: 1.75rem;}
    .headerH1, .headerQuote { font-size: 1.2rem;}
    .headerQuote {font-size: 1rem;}
    .headerQuote::before, .headerQuote::after{font-size: 3rem;}
    .chartSearchTags { font-size: .9rem !important;}
    .kaizen {width: 80%;}
}
