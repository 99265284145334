.blogList-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .5rem;
}
@media (max-width: 768px) {
    .blogList-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 600px) {
    .blogList-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
    .blogItem-wrapper {
        max-height: 570px;
    }
    .searchBar-wrapper {
        margin: 1rem auto;
    }
   
    .hideChip {
        display:none;
    }
    .myh1 {
        font-size: 1.75rem;
    }
    .myh2 {
        font-size: 1.4rem;
    }
    .myh3 {
        font-size: 1.2rem;
    }
    .individualBlogH1 {
        font-size: 1.75rem;
    }
}